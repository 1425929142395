import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          "OrderInfoHeading": "Order Information",
          "OrderInfoAdressHeader": "Information",
          "OrderInfoAdress": "Address",
          "OrderInfoEstateType": "Property Type",
          "OrderInfoEstateFloorCount": "Number of Floors",
          "OrderInfoEstateRoomCount": "Number of Rooms",
          "OrderInfoEstateLivingArea": "Living Area",
          "OrderInfoEstateBusinessArea": "Business Area",
          "OrderRawMaterialDownloadHeading": "Raw Material",
          "OrderRawMaterialClickToDownload": "Click here to download the raw material.",
          "OrderStatusHeading": "Status",
          "OrderStatusDelivered": "The order has been delivered",
          "OrderRawMaterialClickToComplete": "Click here to mark the order as delivered.",
          "OrderMediaHeading": "Media",
          "OrderMediaSubHeading": "Upload the finished material here",
          "OrderMediaUploadButton": "Upload Material",
          "OrderUploadModalUploadText": "Select one or more files or drag them here.",
          "OrderUploadModalSizeWarning": "One or more of the images exceed the dimensions 3000 x 3000.<br />If they are uploaded to the housing systems, they will downscale the images, which can result in loss of quality.",
          "OrderUploadModalCancelButton": "Cancel",
          "OrderUploadModalUploadButton": "Upload",
          "OrderChangeSaved": "The change has been saved.",
          "OrderChangeSaveFailed": "Updating the order failed.",
          "OrderEstateContactHeading": "Property Actor",
          "OrderEstateContactName": "Name",
          "OrderEstateContactPhoneNo": "Phone No.",
          "OrderEstateContactEmail": "Email",
          "OrderMediaTextHeading": "Texts",
          "OrderMediaTextSubHeading": "Fill in the following texts",
          "OrderMediaInputHeading": "Heading",
          "OrderMediaInputTextSmall": "Text - small",
          "OrderMediaInputTextMedium": "Text - medium",
          "OrderMediaInputTextLarge": "Text - long",
          "OrderMediaInputTextSoMe": "Text - SoMe",
          "OrderMediaInputTextWebsite": "Text - Website",
          "FileTypeError": "One or more of the files are not JPG or MP4.",
          "PhotographyDate": "Photography date",
          "OrderListHeading": "Orders",
          "OrderListHeaderCaseNo": "Item no.",
          "OrderListHeaderAddress": "Address",
          "OrderListHeaderCustomer": "Customer",
          "OrderListHeaderImageCount": "Images",
          "OrderListHeaderCreated": "Created",
          "OrderListHeaderStatus": "Status",
          "TopMenu_Orders": "Orders",
          "subcontractor_status_sent": "Received",
          "subcontractor_status_downloaded": "Downloaded",
          "subcontractor_status_completed": "Delivered",
          "subcontractor_status_rejected": "Rejected",
          "subcontractor_status_canceled": "Canceled",
          "OrderListHeaderPhoteshotDate" : "Photoshot Date",
          "MessagesHeader": "Messages",
          "MessagesItemHeaderSubject" : "Subject",
          "MessagesItemHeaderRecived" : "Recived",
          "CloseMessage": "Close",
          "ReplyMessage": "Reply",
          "SentMessage": "Send",
          "OrderCustomerName" : "Real Estate Broker",
          "OrderItemNo" : "Item no.",
        }
      },
      da: {
        translations: {
          "OrderInfoHeading": "Ordre information",
          "OrderInfoAdressHeader": "Information",
          "OrderInfoAdress": "Adresse",
          "OrderInfoEstateType": "Ejendomstype",
          "OrderInfoEstateFloorCount": "Antal etager",
          "OrderInfoEstateRoomCount": "Antal rum",
          "OrderInfoEstateLivingArea": "Boligareal",
          "OrderInfoEstateBusinessArea": "Erhvervs areal",
          "OrderRawMaterialDownloadHeading": "Råmateriale",
          "OrderRawMaterialClickToDownload": "Klik her for at hente det rå materiale.",
          "OrderStatusHeading": "Status",
          "OrderStatusDelivered": "Ordren er leveret",
          "OrderRawMaterialClickToComplete": "Klik her for at markere ordren som leveret.",
          "OrderMediaHeading": "Media",
          "OrderMediaSubHeading": "Upload det færdige materiale her",
          "OrderMediaUploadButton": "Upload meteriale",
          "OrderUploadModalUploadText": "Vælg en eller flere filer eller træk dem herhen.",
          "OrderUploadModalSizeWarning": "Et eller flere af billederne, overstiger dimensionerne 3000 x 3000.<br />Hvis de uploades til boligsystemerne vil de nedskalere billederne, hvilket kan resultere i tab af kvalitet.",
          "OrderUploadModalCancelButton": "Fortryd",
          "OrderUploadModalUploadButton": "Upload",
          "OrderChangeSaved": "Ændringen er blevet gemt.",
          "OrderChangeSaveFailed": "Opdatering af ordren fejlede.",
          "OrderEstateContactHeading": "Kontaktperson",
          "OrderEstateContactName": "Navn",
          "OrderEstateContactPhoneNo": "Telefon nr.",
          "OrderEstateContactEmail": "E-mail",
          "OrderMediaTextHeading": "Tekster",
          "OrderMediaTextSubHeading": "Udfyld nedenstående tekster",
          "OrderMediaInputHeading": "Overskrift",
          "OrderMediaInputTextSmall": "Tekst - lille",
          "OrderMediaInputTextMedium": "Tekst - mellem",
          "OrderMediaInputTextLarge": "Tekst - lang",
          "OrderMediaInputTextSoMe": "Tekst - SoMe",
          "OrderMediaInputTextWebsite": "Tekst - Website",
          "FileTypeError": "En eller flere af filerne er ikke JPG eller MP4.",
          "PhotographyDate": "Fotografering d.",
          "OrderListHeading": "Bestillinger",
          "OrderListHeaderCaseNo": "Sagsnr.",
          "OrderListHeaderAddress": "Adresse",
          "OrderListHeaderCustomer": "Kunde",
          "OrderListHeaderImageCount": "Antal billeder",
          "OrderListHeaderCreated": "Created",
          "OrderListHeaderStatus": "Status",
          "TopMenu_Orders": "Bestillinger",
          "subcontractor_status_sent": "Modtaget",
          "subcontractor_status_downloaded": "Hentet",
          "subcontractor_status_completed": "Ordren er leveret",
          "subcontractor_status_rejected": "Ordren er afvist",
          "subcontractor_status_canceled": "Ordren er annulleret",
          "OrderListHeaderPhoteshotDate" : "Fotografering d.",
          "MessagesHeader": "Beskeder",
          "MessagesItemHeaderSubject" : "Emne",
          "MessagesItemHeaderRecived" : "Modtaget",
          "CloseMessage": "Luk",
          "ReplyMessage": "Svar",
          "SentMessage": "Send",
          "OrderCustomerName" : "Ejendomsmægler",
          "OrderItemNo" : "Sagsnr.",
          // Add more translations here
        }
      },
      // Add more languages here
    },
    lng: "da-DK",
    fallbackLng: "da-DK",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    react: {
      wait: true
    }
  });

export default i18n;